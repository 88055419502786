// import React from 'react';
import Month from '../models/months';
import MonthlyOffer from '../models/monthly-offer';
// import ExternalLink from '../components/ExternalLink';

const offersCollection: MonthlyOffer[] = [
  // ##### TEMPLATE
  // {
  //   month: Month.XX,
  //   year: 2022,
  //   offers: [
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.jpg',
  //       offerPrice: 0.0,
  //       originalPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.jpg',
  //       offerPrice: 0.0,
  //       originalPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.jpg',
  //       offerPrice: 0.0,
  //       originalPrice: 0.0,
  //     },
  //   ],
  // },
  // #####
  {
    month: Month.June,
    year: 2022,
    offers: [
      {
        title: 'Levocetirizin AL',
        description: 'bei Allergien, 5 mg Filmtabletten, 50 St.',
        imgFile: 'levocetirizin_al.png',
        offerPrice: 8.95,
      },
      {
        title: 'Magnesium Verla',
        description: 'Dragées, 50 St.',
        imgFile: 'magnesium_verla.png',
        offerPrice: 4.99,
      },
      {
        title: 'Mykosert Creme',
        description: 'bei Fußpilz,20g',
        imgFile: 'mykosert_creme.png',
        offerPrice: 9.99,
      },
    ],
  },
  {
    month: Month.May,
    year: 2022,
    offers: [
      {
        title: 'Meditonsin',
        description: 'Tropfen, 35g',
        imgFile: 'meditonsin.png',
        offerPrice: 7.95,
      },
      {
        title: 'Angocin',
        description: 'bei Blasenentzündung, Filmtabletten, 50 St.',
        imgFile: 'angocin.png',
        offerPrice: 9.95,
      },
      {
        title: 'Pantoprazol',
        description: '20 mg Tabletten, 14 St.',
        imgFile: 'pantoprazol.png',
        offerPrice: 8.95,
      },
    ],
  },
];

export default offersCollection;
